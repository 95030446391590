import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`12th August 2024`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Outlier Detection for Connections`}</strong>{`: You can now use `}<a parentName="p" {...{
            "href": "type://OutlierDetection:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/outlier/OutlierDetection.html"
          }}>{`type://OutlierDetection`}</a>{` to detect unhealthy
connections and close them gracefully. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5751"
          }}>{`#5751`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5763"
          }}>{`#5763`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`OutlierDetectingRule rule =
  OutlierDetectingRule
    .builder()
    .onServerError()
    .onException(IOException.class)
    .onException(WriteTimeoutException.class, OutlierDetectionDecision.FATAL)
    .build();

OutlierDetection outlierDetection = 
  OutlierDetection
    .builder(rule)
    .counterSlidingWindow(Duration.ofSeconds(10))
    .counterUpdateInterval(Duration.ofSeconds(1))
    .failureRateThreshold(0.5)
    .build();

ClientFactory
  .builder()
  // Apply the OutlierDetection to detect and close unhealthy connections
  .connectionOutlierDetection(outlierDetection)
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Automatic value loader`}</strong>{`: You can now use an `}<a parentName="p" {...{
            "href": "type://AsyncLoader:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/AsyncLoader.html"
          }}>{`type://AsyncLoader`}</a>{` to (re)load a value atomically, lazily and
asynchronously. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5506"
          }}>{`#5506`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5590"
          }}>{`#5590`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Function<String, CompletableFuture<String>> loader = cache -> {
  ResponseEntity<String> response =
    client.prepare().get("/api/v1/items").asString().execute();
    return response.thenApply(res -> res.content());
};

AsyncLoader<String> asyncLoader =
  AsyncLoader
    .builder(loader)
    .expireAfterLoad(Duration.ofSeconds(60))
    .build();
asyncLoader.get().join();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Configurable Response Timeout`}</strong>{`: You can now use `}<a parentName="p" {...{
            "href": "type://ResponseTimeoutMode:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ResponseTimeoutMode.html"
          }}>{`type://ResponseTimeoutMode`}</a>{` to configure when a response
timeout starts for a client. The timeout can be set when a request is started, a connection is acquired or a
request is fully sent. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4591"
          }}>{`#4591`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5793"
          }}>{`#5793`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientFactory
  .builder()
  .responseTimeout(Duration.ofSeconds(5))
  // A request will be timed out if the response is not fully received
  // within 5 seconds.
  .responseTimeoutMode(ResponseTimeoutMode.FROM_START)
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Fallback File Extensions`}</strong>{`: You can now set fallback file extensions in `}<a parentName="p" {...{
            "href": "type://FileService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/FileService.html"
          }}>{`type://FileService`}</a>{`.
This feature is useful when you want to map a RESTful URI to a static resource file. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4542"
          }}>{`#4542`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5806"
          }}>{`#5806`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`FileService
  .builder(rootDir)
  // '/app/projects' path could be mapped to '<rootDir>/app/projects.html'
  .fallbackFileExtensions("html")
  ...
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Sending Arbitrary Content in gRPC-JSON Transcoding`}</strong>{`: You can now use `}<a parentName="p" {...{
            "href": "https://github.com/googleapis/googleapis/blob/master/google/api/httpbody.proto"
          }}>{`google.api.HttpBody`}</a>{`
to `}<a parentName="p" {...{
            "href": "https://www.envoyproxy.io/docs/envoy/latest/configuration/http/http_filters/grpc_json_transcoder_filter#sending-arbitrary-content"
          }}>{`send arbitrary content`}</a>{`
in gRPC-JSON transcoding. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5311"
          }}>{`#5311`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5400"
          }}>{`#5400`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-protobuf"
          }}>{`rpc ArbitraryHttp(google.api.HttpBody) returns (google.api.HttpBody) {
  option (google.api.http) = {
    post: "/v1/arbitrary"
  };
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Zone-Aware Load Balancing`}</strong>{`: `}<a parentName="p" {...{
            "href": "type://XdsEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/xds/client/endpoint/XdsEndpointGroup.html"
          }}>{`type://XdsEndpointGroup`}</a>{` now supports `}<a parentName="p" {...{
            "href": "https://www.envoyproxy.io/docs/envoy/latest/intro/arch_overview/upstream/load_balancing/zone_aware"
          }}>{`zone-aware`}</a>{`
load balancing which prioritizes sending traffic to local zones. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5785"
          }}>{`#5785`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5808"
          }}>{`#5808`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`WebSocketService Testing`}</strong>{`: You can now use `}<a parentName="p" {...{
            "href": "type://ServerExtension#webSocketClient():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/testing/junit5/server/ServerExtension.html#webSocketClient()"
          }}>{`type://ServerExtension#webSocketClient()`}</a>{` to test `}<a parentName="p" {...{
            "href": "type://WebSocketService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/websocket/WebSocketService.html"
          }}>{`type://WebSocketService`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5538"
          }}>{`#5538`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5765"
          }}>{`#5765`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`Armeria client now supports the `}<a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/100"
        }}><inlineCode parentName="a">{`Expect: 100-continue`}</inlineCode></a>{` header. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5646"
        }}>{`#5646`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ClientRequestContext#cancel():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContext.html#cancel()"
        }}>{`type://ClientRequestContext#cancel()`}</a>{` now cancels the associated request immediately. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5800"
        }}>{`#5800`}</a></li>
      <li parentName="ul">{`The health checking behavior of `}<a parentName="li" {...{
          "href": "type://XdsEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/xds/client/endpoint/XdsEndpointGroup.html"
        }}>{`type://XdsEndpointGroup`}</a>{` is more aligned with Envoy. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5785"
        }}>{`#5785`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.envoyproxy.io/docs/envoy/latest/intro/arch_overview/upstream/load_balancing/subsets#load-balancer-subsets"
        }}>{`Subsets`}</a>{` now
supports features available in the default load balancer of `}<a parentName="li" {...{
          "href": "type://XdsEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/xds/client/endpoint/XdsEndpointGroup.html"
        }}>{`type://XdsEndpointGroup`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5801"
        }}>{`#5801`}</a></li>
      <li parentName="ul">{`New headers from Guava have been added to `}<a parentName="li" {...{
          "href": "type://HttpHeaderNames:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpHeaderNames.html"
        }}>{`type://HttpHeaderNames`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5789"
        }}>{`#5789`}</a></li>
      <li parentName="ul">{`Armeria is now compatible with Thrift 0.19 and 0.20.0 `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5243"
        }}>{`#5243`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5822"
        }}>{`#5822`}</a></li>
      <li parentName="ul">{`Armeria client now silently ignores content instead of raising an exception when content exists for
a status that indicates empty content. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5823"
        }}>{`#5823`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`Validation for URI's authority is relaxed to permit underscores (`}<inlineCode parentName="li">{`_`}</inlineCode>{`). `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5814"
        }}>{`#5814`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5854"
        }}>{`#5854`}</a></li>
      <li parentName="ul">{`A user can send and receive an HTTP request whose `}<inlineCode parentName="li">{`:path`}</inlineCode>{` contains the following characters now: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5845"
        }}>{`#5845`}</a>
        <ul parentName="li">
          <li parentName="ul">{`TAB (0x09)`}</li>
          <li parentName="ul">{`FS (0x1C)`}</li>
          <li parentName="ul">{`GS (0x1D)`}</li>
          <li parentName="ul">{`RS (0x1E)`}</li>
          <li parentName="ul">{`US (0x1F)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Fixed a potential leak when sending compressed responses. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5858"
        }}>{`#5858`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`ResteasyClient`}</inlineCode>{` now correctly releases `}<a parentName="li" {...{
          "href": "type://ClientFactory:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html"
        }}>{`type://ClientFactory`}</a>{` when it is closed. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5833"
        }}>{`#5833`}</a></li>
      <li parentName="ul">{`Content decompression exceptions are now properly propagated. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5177"
        }}>{`#5177`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5620"
        }}>{`#5620`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://@Blocking:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Blocking.html"
        }}>{`type://@Blocking`}</a>{` now correctly works with gRPC-Kotlin stubs `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5295"
        }}>{`#5295`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5399"
        }}>{`#5399`}</a></li>
      <li parentName="ul">{`Fixed `}<a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{` sidebar to occupy at most 40% of the window width. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5091"
        }}>{`#5091`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5839"
        }}>{`#5839`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`InputStream`}</inlineCode>{` created from `}<a parentName="li" {...{
          "href": "type://StreamMessage#toInputStream(Function):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#toInputStream(java.util.function.Function)"
        }}>{`type://StreamMessage#toInputStream(Function)`}</a>{` now correctly raises
an exception if the original `}<a parentName="li" {...{
          "href": "type://StreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html"
        }}>{`type://StreamMessage`}</a>{` is closed with an exception. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5859"
        }}>{`#5859`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ClientRequestContext#isTimedOut():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContext.html#isTimedOut()"
        }}>{`type://ClientRequestContext#isTimedOut()`}</a>{` now correctly returns `}<inlineCode parentName="li">{`true`}</inlineCode>{` if a DNS query, a session creation or
a write operation times out. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4935"
        }}>{`#4935`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5156"
        }}>{`#5156`}</a></li>
    </ul>
    <h2 {...{
      "id": "-documentation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-documentation",
        "aria-label": " documentation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📃 Documentation`}</h2>
    <ul>
      <li parentName="ul">{`Added a gRPC reverse proxy server example. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2353"
        }}>{`#2353`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5722"
        }}>{`#5722`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`Modified the behavior of `}<a parentName="li" {...{
          "href": "type://Endpoint#withAttrs(Attributes):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html#withAttrs(com.linecorp.armeria.common.Attributes)"
        }}>{`type://Endpoint#withAttrs(Attributes)`}</a>{` to merge the original `}<a parentName="li" {...{
          "href": "type://Attributes:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Attributes.html"
        }}>{`type://Attributes`}</a>{`
with the new one. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5802"
        }}>{`#5802`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Instead, `}<a parentName="li" {...{
              "href": "type://Endpoint#replaceAttrs(Attributes):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html#replaceAttrs(com.linecorp.armeria.common.Attributes)"
            }}>{`type://Endpoint#replaceAttrs(Attributes)`}</a>{` is added to replace the entire attributes of `}<a parentName="li" {...{
              "href": "type://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
            }}>{`type://Endpoint`}</a>{`.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Curator 5.6.0 → 5.7.0`}</li>
      <li parentName="ul">{`Dropwizard Metrics 4.2.25 → 4.2.26`}</li>
      <li parentName="ul">{`Eureka 2.0.2 → 2.0.3`}</li>
      <li parentName="ul">{`GraphQL Kotlin 7.1.1 → 7.1.4`}</li>
      <li parentName="ul">{`gRPC Java 1.64.0 → 1.65.1`}</li>
      <li parentName="ul">{`Jackson 2.17.1 → 2.17.2`}</li>
      <li parentName="ul">{`Jetty 9.4.54 → 9.4.55, 11.0.20 → 11.0.22, 12.0.8 → 12.0.12`}</li>
      <li parentName="ul">{`Kafka client 3.7.0 → 3.8.0`}</li>
      <li parentName="ul">{`Kubernetes client 6.12.1 → 6.13.1`}</li>
      <li parentName="ul">{`Micrometer 1.13.0 → 1.13.2`}</li>
      <li parentName="ul">{`Micrometer Tracing 1.2.4 → 1.3.2`}</li>
      <li parentName="ul">{`Netty 4.1.110 → 4.1.112`}</li>
      <li parentName="ul">{`Prometheus 1.3.0 → 1.3.1`}</li>
      <li parentName="ul">{`Reactor 3.6.6 → 3.6.8`}</li>
      <li parentName="ul">{`Sangria 4.1.0 → 4.1.1`}</li>
      <li parentName="ul">{`Scala 3.4.1 - 3.4.2`}</li>
      <li parentName="ul">{`ScalaPB 0.11.15 → 0.11.17`}</li>
      <li parentName="ul">{`SLF4J 2.0.12 → 2.0.13`}</li>
      <li parentName="ul">{`Spring 6.1.8 → 6.1.11`}</li>
      <li parentName="ul">{`Spring Boot 3.3.0 → 3.3.2`}</li>
      <li parentName="ul">{`Tomcat 9.0.87 → 9.0.91, 10.1.20 → 10.1.26`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['Be-poz', 'Dogacel', 'KarboniteKream', 'eottabom', 'ikhoon', 'injae-kim', 'jrhee17', 'kojilin', 'minwoox', 'moromin', 'patcher454', 'seonWKim', 'thomasbruggink', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      